import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class EntranceExamService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/entrance-exam`;
    }

    paginate(data) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }


    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    saveCandidate(id, data) {
        let url = `${this.#api}/${id}/save/candidate`
        return apiService.post(url, data);
    }

    examConducted(id) {
        let url = `${this.#api}/${id}/conducted`
        return apiService.get(url);
    }

    resultPublished(id) {
        let url = `${this.#api}/${id}/result/published`
        return apiService.get(url);
    }

    resultConfirmed(id) {
        let url = `${this.#api}/${id}/confirmed`
        return apiService.get(url);
    }

    notifyExam(id) {
        let url = `${this.#api}/${id}/notify/exam`;
        return apiService.get(url);
    }

    notifyResult(id) {
        let url = `${this.#api}/${id}/notify/result`
        return apiService.get(url);
    }

    saveExamResult(id, data) {
        let url = `${this.#api}/${id}/save/exam-result`
        return apiService.post(url, data);
    }
    getAllEntranceExam(preRegistrationId){
        let url = `${this.#api}/get-by/${preRegistrationId}/pre-registration`;
        return apiService.get(url);
    }
    getEntranceDetail(preRegistrationId,entranceId){
        let url = `${this.#api}/pre-registration/${preRegistrationId}/get/entrance-detail/${entranceId}`;
        return apiService.get(url);
    }
    previewAllCandidatesPDF(entranceId,data={})
    {
        let url = `${this.#api}/candidates/${entranceId}/preview-in-pdf`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    notifyOrientationInBulk(entranceId){
        let url = `${this.#api}/notify/${entranceId}/orientation-in-bulk`;
        return apiService.get(url);
    }

}
