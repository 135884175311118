<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
              <div class="row">
              <div class="col-6">
                <h4>
                  Entrance exam candidate
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                <router-link :to="{ name: 'entrance-exam' }">
                  Entrance exam \
                </router-link>
                Result
              </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">

                  <v-btn depressed class=" text-gray btn btn-standard m-1" @click="redirectToEntrance()">
                  Cancel
                </v-btn>
                <v-btn depressed class="text-white btn btn-primary m-1" @click="saveResult()">
                  Save
                </v-btn>

              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead>
                  <th class="wrap-column px-3">Candidate info</th>


<!--                  <th class="wrap-column px-3">Registration Status</th>-->
                  <th class="wrap-column px-3">Exam Status</th>
                  <th class="wrap-column px-3">Obtained Mark</th>
                  <th class="wrap-column px-3">Interview date</th>
                  <th class="wrap-column px-3">Interview time</th>
                  <th class="wrap-column px-3">Scholarship</th>
                  <th class="wrap-column px-3">Remarks</th>
                  </thead>
                  <tbody>
                  <tr v-for="(candidate, index) of candidates" :key="index">
                    <td class=" px-3">
                        <div class="text-secondary">
                          <span class="font-weight-medium font-size-lg">
                            Full name : {{ candidate.full_name }}
                          </span>
                        </div>
                        <div class="text-secondary">
                            <span class="font-weight-medium font-size-md">Email : {{ candidate.email }}</span>
                        </div>
                        <div class="text-secondary">
                            <span class="font-weight-medium font-size-md">Enrolment No: {{ candidate.symbol_no }}</span>
                        </div>
                    </td>


<!--                  <td class="wrap-column px-3 pt-4">
                      <v-select readonly @change="makeObtainedMarkNull(index)" :items="registration_statuses"
                                v-model="candidate.registration_status" outlined dense label="Registration Status"
                                item-value="value" item-text="title"></v-select>
                    </td>-->
                    <td class="px-3 py-3 vertical_align_center" style="width: 40px !important; white-space: pre-wrap;">
                      <v-select  :items="exam_statuses"
                                v-model="candidate.exam_status" outlined dense
                                label="Exam Status" item-value="value"
                                item-text="title"></v-select>
                    </td>

                    <td class="wrap-column px-3 pt-4">
                      <v-text-field type="number"
                                    min="0" outlined dense v-model="candidate.obtained_marks"
                                    label="Obtained mark"></v-text-field>
                    </td>
                      <td class="wrap-column px-3 pt-4">
                          <v-menu

                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field dense outlined
                                          v-model="candidate.interview_date"
                                          label="Interview date"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                  ></v-text-field>
                              </template>
                              <v-date-picker
                                      v-model="candidate.interview_date"
                                      @input="menu2 = false"
                              ></v-date-picker>
                          </v-menu>

                      </td>
                      <td class="wrap-column px-3 pt-4">
                          <v-text-field type="time" outlined dense v-model="candidate.interview_time" label="Interview time"></v-text-field>
                      </td>
                    <td class="wrap-column px-3 pt-4">
                      <v-text-field dense outlined v-model="candidate.scholarship" label="Scholarship"></v-text-field>
                    </td>
                    <td class="wrap-column px-3 pt-4">
                      <v-textarea dense outlined v-model="candidate.scholarship_remarks" placeholder="Scholarship"></v-textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
<!--            <div class="row" v-if="candidates.length>0">-->
<!--              <div class="col-12 text-center">-->
<!--                <b-pagination-->
<!--                    @input="getCandidateList(entrance_id)"-->
<!--                    v-model="page"-->
<!--                    :total-rows="total"-->
<!--                    :per-page="perPage"-->
<!--                    first-number-->
<!--                    last-number-->
<!--                ></b-pagination>-->
<!--                &lt;!&ndash;  &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>Update Status</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select v-model="registration_status" :items="registration_statuses" label="Registration Status"
                        outlined dense item-value="value"
                        item-text="title"></v-select>
            </v-col>
            <v-col cols="12">
              <v-select v-model="exam_status" :items="exam_statuses" label="Exam Status" outlined dense
                        item-value="value"
                        item-text="title"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false; exam_status=null; registration_status=null;" class="btn btn-standard text-white"
                 depressed>Cancel
          </v-btn>
          <v-btn :loading="loading" @click="updateEnrollmentStatus" class="btn btn-primary text-white"
                 depressed>Update Status
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import EntranceExamService from "@/core/services/entrance-exam/EntranceExamService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

import {API_URL} from "@/core/config";

const entranceService = new EntranceExamService();
const enrollmentService = new UserEnrollmentService();

export default {
  name: "SetResult",
  data() {
    return {
        menu2: false,
      candidates: [],
      dialog: false,
      loading: false,
      registration_status: null,
      exam_status: null,
      page: null,
      total: null,
      perPage: null,
      exam_statuses: [
        {title: 'Pending', value:'pending'},
        {title: 'Attended', value: 'attended'},
        {title: 'Not attended', value: 'not_attended'},
      ],
        registration_statuses: [
        {title: 'Active', value: 'active'},
        {title: 'Schedule', value: 'schedule'},
        {title: 'Cancel', value: 'cancel'},
      ],
    }
  },
  computed: {
    entrance_id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if (this.entrance_id) {
      this.getEntrance();
    }
  },
  methods: {
    redirectToEntrance() {
      this.$router.push({name: 'entrance-exam'})
    },
    getEntrance() {
      entranceService.show(this.entrance_id).then(response => {
        this.entrance = response.data.entrance
        this.getCandidateList(this.entrance.id);
        this.candidates = this.entrance.candidates;
      })
    },
    getCandidateList(entranceId) {
      this.$bus.emit('toggleLoader');
      enrollmentService.selectedCandidate(entranceId).then(response => {
        this.candidates = response.data.enrollments;
        // this.page = response.data.meta.current_page;
        // this.total = response.data.meta.total;
        // this.perPage = response.data.meta.per_page;
        this.$bus.emit('toggleLoader');
      }).catch(error=>{
        this.$bus.emit('toggleLoader');
      })
    },
    saveResult() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.$bus.emit('toggleLoader');
            entranceService.saveExamResult(this.entrance_id, this.candidates).then(response => {
              this.$bus.emit('toggleLoader');
              this.$snotify.success("Result has been Information updated");
              this.getEntrance();
            }).catch(error => {
              this.$bus.emit('toggleLoader');
              this.$snotify.error("Something went wrong. Please try again later")
            })
          }
        }
      });

    },
    makeObtainedMarkNull(index) {
      this.candidates[index].obtained_marks = 0;
    },

    updateEnrollmentStatus() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data = {
              exam_status: this.exam_status ? this.exam_status : null,
              registration_status: this.registration_status ? this.registration_status : null
            }
            this.loading = true
            enrollmentService.updateEnrollmentStatus(this.entrance.pre_registration_id, this.entrance.id, data).then(response => {
              this.loading = false
              this.$snotify.success("Status has been Information updated")
              this.exam_status = null
              this.reg = null
              this.dialog = false
              this.getEntrance();
            }).catch(error => {
              this.loading = false
              this.$snotify.error("Something went wrong. Please try again later")
            })
          }
        }
      });

    }
  }
}
</script>

<style scoped>

</style>