import { render, staticRenderFns } from "./SetResult.vue?vue&type=template&id=b7fa265a&scoped=true"
import script from "./SetResult.vue?vue&type=script&lang=js"
export * from "./SetResult.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7fa265a",
  null
  
)

export default component.exports